import React, { useState } from 'react';
import logo from '../images/shopersbay_logo.webp';
import { Link } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuTrue, setToggleTrue } from '../redux/slice/changeState'
import Contact from './Contact';

const Header = () => {
  const toggleMenu = useSelector((state) => state.toggle)
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleSignUp = () => {
    window.open("https://login.shopersbay.com/module?q=shopersbay", "_blank");

  }

  const handleSignIn = () => {
    window.open("https://login.shopersbay.com/shopersbay", "_blank");
  }
  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className='img-fluid' />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> {/* Align right on desktop */}
              <li className="nav-item">
                <Link to="pricing" smooth={true} duration={1000} onClick={closeMenu}>Pricing</Link>
              </li>
              <li className="nav-item" onClick={() => { closeMenu(); dispatch(setToggleTrue()); }}>

                Contact Us

              </li>
              <li className="nav-item">
                <Link to="faqs" smooth={true} duration={1000} onClick={closeMenu}>FAQs</Link>
              </li>
            </ul>
            <div className='btn-section'>
              <button className='common-btn ' onClick={handleSignIn}>Sign In</button>
              <button className='odd-btn' onClick={handleSignUp}>Try For Free</button>
            </div>
          </div>

        </div>
      </nav>
      {
        toggleMenu.hastoggle &&
        <Contact />
      }
    </>
  );
};

export default Header;

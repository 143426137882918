import React from 'react'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/module-ellipse.webp'

const Ready = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const handleSignUp = () => {
        window.open("https://login.shopersbay.com/module?q=shopersbay", "_blank");
    
      }

    return (
        <div className='ready-section text-center position-relative z-1'>
            <img src={img1} className='img-fluid position-absolute' alt=""></img>
            <Container>
                <h5 className='primary-text'>Ready to Transform Your Business?</h5>
                <h2 className='text-capitalize'>Join Thousands of Successful Brands on Shopersbay</h2>
                <h6>Take charge of your business with Shopersbay—the ultimate platform for managing and growing your online presence. Start today with our intuitive tools and experience the difference firsthand!</h6>
                <button className='common-btn'  onClick={handleSignUp}>Sign Up for Free</button>
            </Container>
        </div>
    )
}

export default Ready

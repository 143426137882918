export function ContactFormRules(values){
    let errors = {};

    if (values.name === '') {
        errors.name = 'Name is required';
    }
    if (values.email === '') {
        errors.email = 'Email is required';
    }
    if (values.phone === '') {
        errors.phone = 'Number is required';
    }   
    if (values.subject === '') {
        errors.subject = 'Subject is required';
    }   
    if (values.message === '') {
        errors.message = 'Please enter your message';
    }   
    return errors; 
}

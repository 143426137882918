import { configureStore } from '@reduxjs/toolkit'
import masterReducer from './slice/onLoad'
import toggleReducer from './slice/changeState'

const store = configureStore({
    reducer: {
        toggle: toggleReducer,
        master : masterReducer,
    },
})
export default store;
import React, {useState} from 'react'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import { setToggleFalse } from '../redux/slice/changeState'
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ContactFormRules } from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import { saveContact } from '../redux/slice/onLoad'
import { AiOutlineExclamationCircle } from "react-icons/ai";


const Contact = () => {
    const dispatch = useDispatch()
    const toggle = useSelector((state) => state.toggle)
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const handleClose = () => {
        dispatch(setToggleFalse())
    }

    const [formValues, setFormValues] = useState(
        {
            "name": "",
            "phone": "",
            "email": "",
            "subject": "",
            "message": "",
        }
    );

    const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        ContactFormRules,
        formValues
      );

    function handleNext() {
        alert()
        dispatch(saveContact(values,setFormValues))
    }


    console.log("errors", errors)
    console.log("values", values)

    return (
        <Modal show={toggle.hastoggle} onHide={handleClose} centered className='contact-form-modal'>
            <Modal.Header closeButton>
                <Modal.Title className='primary-text'>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className={`${isMobile ? '' : 'mb-3'}`}>
                    <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control type="text" placeholder="Name" name="name" onChange={handleChange} value={values.name} autoComplete="new-item" />
                        <span className='error mt-3'>{errors.hasOwnProperty('name') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.name} </>}
                        </span>
                    </Col>
                    <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control type="text" placeholder="Phone Number" name="phone" onChange={handleChange} value={values.phone} autoComplete="new-item" />
                        <span className='error mt-3'>{errors.hasOwnProperty('phone') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.phone} </>}
                        </span>
                    </Col>
                </Row>
                <Row className={`${isMobile ? '' : 'mb-3'}`}>
                    <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control type="email" placeholder="Email" name="email" onChange={handleChange} value={values.email} autoComplete="new-item" />
                        <span className='error mt-3'>{errors.hasOwnProperty('email') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.email} </>}
                        </span>
                    </Col>
                    <Col lg={6} md={12} xs={12} className={`${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control type="text" placeholder="Subject" name="subject" onChange={handleChange} value={values.subject} autoComplete="new-item" />
                        <span className='error mt-3'>{errors.hasOwnProperty('subject') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.subject} </>}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className={`${isMobile ? 'mb-3' : ''}`}>
                        <Form.Control as="textarea" rows={3} placeholder='Enter Message....' value={values.message} name="message" onChange={handleChange} />
                        <span className='error mt-3'>{errors.hasOwnProperty('message') &&
                            <><AiOutlineExclamationCircle className="error-icon" style={{ marginRight: '6px' }} />{errors.message} </>}
                        </span>
                    </Col>
                </Row>
                <button type="submit" className='common-btn mt-4' onClick={handleSubmit}>Submit</button>
            </Modal.Body>
        </Modal>
    )
}

export default Contact

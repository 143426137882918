import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import { GrFacebookOption } from "react-icons/gr";
import logo from '../images/shopersbay_logo.webp';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuTrue, setToggleTrue } from '../redux/slice/changeState'
import Contact from './Contact';

const Footer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const toggleMenu = useSelector((state) => state.toggle)
    const dispatch = useDispatch()
    const handleUrl = () => {
        window.open("https://shopersbay.com/policies/privacy-policy","_blank");
    }

    return (
        <div className={`footer ${isMobile ? "" : "pt-5"} `}>
            <Container>
                <div>
                    <img src={logo} className='img-fluid' alt="logo"></img>
                </div>
                <Row className='footer-row'>
                    <Col lg={7} md={7} className='footer-logo-column pb-30'>

                        <p className='mb-3'>Contact Info:</p>
                        <Row className={`mb-7 contact-info ${isMobile ? '' : 'ml-50'}`}>
                            <div className='mb-2'>
                                <p className='footer-header'><span className='primary-text'>Phone:</span><NavLink to="tel:9885260004" target="_blank" className="text-decoration-none"> +91 9885260004</NavLink></p>
                            </div>
                            <div className='mb-2'>
                                <p className='footer-header'><span className='primary-text'>E-mail:</span><a className="text-decoration-none" href="mailto:info@irarealty.in" rel="noreferrer" target="_blank"> contact@shopersbay.com</a></p>
                            </div>
                            <div className=''>
                                <p className='footer-header'><span className='primary-text'>Hours:</span> Mon - Sat, 10:00 AM - 7:00 PM</p>
                            </div>
                        </Row>
                    </Col>
                    <Col lg={5} md={5} className={`footer-second-column pt-2`}>
                        <Row className={` ${isMobile ? 'flex-fill' : 'ml-50'}`}>
                            <div className='quick-links d-flex align-items-center'>
                                <p className='footer-header'><span className="primary-text" >Quick Links:</span></p>
                                <ul className='list-unstyled'>
                                    <li><Link to="pricing" smooth={true} duration={1000}>Pricing</Link></li>
                                    <li><Link to="faqs" smooth={true} duration={1000}>FAQs</Link></li>
                                    <li onClick={() => dispatch(setToggleTrue())}>Contact Us</li>
                                </ul>
                            </div>
                        </Row>
                        {/* <Row className={` ${isMobile ? 'flex-fill' : 'ml-50 mb-7'}`}>
                            <div className='d-flex align-items-center'>
                                <p className='footer-header'><span className="primary-text" >Social Links:</span></p>
                                <NavLink to="" target="_blank"><GrFacebookOption size={isMobile ? 16 : 20} className='padding-10 text-dark' /></NavLink>
                            </div>
                        </Row> */}
                    </Col>
                </Row>
                <div className='copyright d-flex justify-content-between'>
                    <span>@shopersbay</span>
                    <span onClick={handleUrl}>Privacy Policy</span>
                </div>
            </Container>
        </div>
    )
}

export default Footer

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import mobBg from '../images/mob_banner_bg.webp'
import deskBg from '../images/banner-bg.webp'
import img1 from '../images/desktop_banner.webp'
import img2 from '../images/mob_banner.webp'
import elip1 from '../images/half_ellipse.webp'
import elip2 from '../images/mob_ellipse.webp'
import { useMediaQuery } from 'react-responsive'

const Banner = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 500px)' });

    const handleSignUp = () => {
        window.location.href = "https://login.shopersbay.com/module?q=shopersbay";
    }
    
    return (
        <>
            <div className='banner-section position-relative' style={{ backgroundImage: `url(${isMobile ? mobBg : deskBg})` }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div>
                                <h5 className='primary-text'>Boost Your Business with Shopersbay</h5>
                                <h1>The ultimate   </h1>
                                <h1>all-in-one Platform </h1>
                                <h1 className='mb-2'>for <span>Business Success</span></h1>
                                <h6 className=''>Empowering businesses with advanced tools to streamline operations, scale seamlessly, and drive sustainable growth in a dynamic market.</h6>
                                <div className={`button-div ${isMobile ? "" : "d-flex"}`}>
                                    <button type="submit" className='common-btn' onClick={handleSignUp}>Get Started for Free</button>
                                    <button type="submit" className='odd-btn' onClick={() => (window.location.hash = "#moduler")}>Explore Features</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                  </Container>
                {!isMobile &&
                    <>
                        <img src={elip1} alt="ellipse" className='img-fluid ellipse position-absolute end-0 top-0' ></img>
                        <img src={img1} alt="ellipse" className='img-fluid banner-img position-absolute end-0 ' ></img>
                    </>
                }

            </div>
            {isMobile &&
            <div className='position-relative'>
                <img src={elip2} alt="ellipse" className='img-fluid ellipse w-100 position-relative' ></img>
                <img src={img2} alt="ellipse" className='img-fluid banner-img-mob position-absolute' ></img>
            </div>
            }
        </>
    )
}

export default Banner

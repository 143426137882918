import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { fetchFaqsData } from '../redux/slice/onLoad'



const Faqs = () => {
  const dispatch = useDispatch()
  const faqdata = useSelector((state) => state.master.faqdata);
  const [activeKey, setActiveKey] = useState(null); 

  useEffect(() => {
    dispatch(fetchFaqsData())
  }, [])

  if (!faqdata) return null;

  const column1 = faqdata.slice(0, Math.ceil(faqdata.length / 2));
  const column2 = faqdata.slice(Math.ceil(faqdata.length / 2));

  const handleAccordionToggle = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  const renderAccordion = (items) =>
    items.map((item) => (
      <Accordion.Item eventKey={String(item.faqid)} key={item.faqid}>
        <Accordion.Header>{item.questions}</Accordion.Header>
        <Accordion.Body>{item.answers}</Accordion.Body>
      </Accordion.Item>
    ));

  
  return (
    <div className='faqs-section  background-primary' id="faqs">
      <Container>
        <div className='text-center'>
          <h5 className='primary-text'>FAQs</h5>
          <h2>Frequently asked questions</h2>
          <Row className='mt-5'>
            <Col lg={6}>
              <Accordion activeKey={activeKey} onSelect={(key) => handleAccordionToggle(key)}>
                {renderAccordion(column1)}
              </Accordion>
            </Col>
            <Col lg={6}>
              <Accordion activeKey={activeKey} onSelect={(key) => handleAccordionToggle(key)}>
                {renderAccordion(column2)}
              </Accordion>
            </Col>

          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Faqs

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/client.webp'
import img2 from '../images/client-logo.webp'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

const Client = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const handleSignUp = () => {
        window.open("https://login.shopersbay.com/module?q=shopersbay", "_blank");
    }
    // Import files dynamically from the folder
    const importAll = (requireContext) => {
        return requireContext.keys().map((key) => ({
            name: key.replace('./', ''), // Extract file name
            path: requireContext(key),  // Get file path
        }));
    };

    const images = importAll(require.context('../images/client', false, /\.(png|jpe?g|svg|webp)$/));


    return (
        <>
            <div className='client-section background-primary position-relative'>
                <Container>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className='client-text'>
                                <h5 className='primary-text'>What we offer</h5>
                                <h2>Trusted by Businesses Worldwide</h2>
                                <h6 className='mb-4'>Businesses across industries rely on Shopersbay to transform their operations. From enhancing sales with our <b>POS system</b> to simplifying workflows with our <b>dynamic admin panel</b>, Shopersbay is the preferred choice for retailers aiming to scale.</h6>
                                <div className='client-logos'>
                                    <h5 className='primary-text'>Our Clients</h5>
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Pagination]}
                                        className="mySwiper"
                                    >
                                        {
                                            images.map((file, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <img src={file.path} alt={file.name} className='img-fluid'></img></SwiperSlide>
                                                )
                                            })
                                        }

                                    </Swiper>
                                </div>

                                <button type="submit" className='common-btn mt-4' onClick={handleSignUp}>Get Started</button>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            {isMobile &&
                                <img src={img1} alt="" className='img-fluid '></img>
                            }
                        </Col>
                    </Row>
                </Container>
                {!isMobile &&
                    <img src={img1} alt="" className='img-fluid position-absolute client-banner'></img>
                }
            </div>
        </>
    )
}

export default Client

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchModuleData } from '../redux/slice/onLoad'
import { Container, Row, Col } from 'react-bootstrap';
import { FaPlus, FaMinus } from "react-icons/fa6";
import ing1 from '../images/pricing-ellipse.webp'
import ing2 from '../images/pricing-mob.webp'
import { addSelectedModule, setCalculateddata, setAnnualDiscount } from '../redux/slice/onLoad';
import { useMediaQuery } from 'react-responsive'

const Pricing = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const { moduledata, selectedModules, calculateddata } = useSelector((state) => state.master);
  const [currency, setCurrency] = useState("")
  const [activeButton, setActiveButton] = useState("month");

  const handleClick = (dura) => {
    setActiveButton(dura)
    dispatch(fetchModuleData({ vendor_id: 0, duration: dura, duration_type: "free_trial" }))

  }

  const handleCheckboxChange = (moduleData) => {
    const isModuleSelected = selectedModules.some((item) => item.id === moduleData.id);
    let updatedModules;

    if (isModuleSelected) {
      // Remove the module from the selected list
      updatedModules = selectedModules.filter((item) => item.id !== moduleData.id);
    } else {
      // Add the module to the selected list
      updatedModules = [...selectedModules, moduleData];
    }

    const selectedId = updatedModules.map(item => item.module);

    setCurrency(moduleData.currency)
    // dispatch(setAnnualDiscount(moduleData.annual_discount));

    dispatch(addSelectedModule(updatedModules));

    const grandTotal = updatedModules.reduce((sum, { price, tax_percent }) => {
      const numericPrice = parseFloat(price);
      const numericTaxPercent = parseFloat(tax_percent);
      const taxAmount = (numericPrice * numericTaxPercent) / 100;
      const totalForItem = numericPrice + taxAmount;
      return sum + totalForItem;
    }, 0);
    const grand = grandTotal.toFixed(2);

    let totalSaving = 0;

    if (activeButton === "annual") {
      totalSaving = updatedModules.reduce((sum, { annual_discount }) => {
        return sum + parseFloat(annual_discount || 0);
      }, 0);
    }



    const dataCalculated = { ...calculateddata, grandTotal: grand, totalSaving: totalSaving.toFixed(2) };

    dispatch(setCalculateddata(dataCalculated));

  }


  useEffect(() => {
    const input_data = {
      duration: "month",
      duration_type: "free_trail",
      vendor_id: 0
    }
    dispatch(fetchModuleData(input_data))
  }, [])

  return (
    <div className='pricing-section position-relative' id="pricing">
      <Container className='position-relative z-2'>
        <Row>
          <Col lg={8}>
            <div>
              <h5 className='primary-text'>Flexible Pricing Plans</h5>
              <h2>Affordable E-commerce Solutions for Every Business</h2>
            </div>
          </Col>
          <Col lg={4} className='d-flex align-items-center justify-content-end'>
            <div className='text-end'>
              <button className={`monthly-btn  ${activeButton === "month" ? "active" : ""}`} onClick={() => handleClick("month")}>Monthly</button>
              <button className={`yearly-btn ${activeButton === "annual" ? "active" : ""}`} onClick={() => handleClick("annual")}>Yearly</button>
            </div>
          </Col>
        </Row>
        <Row className={`${isMobile ? "mt-4" : ""}`}>
          {moduledata != null && moduledata.map((ele, i) =>
            ele.child_list.map((data, ind) => {
              const isSelected = selectedModules.some((item) => item.id === data.id);
              return (
                <Col lg={3} className={`${isMobile ? "mb-2" : "mb-4"}`}>
                  <div className={`d-flex justify-content-between align-items-center module-list ${isSelected ? "selected" : ""}`} onClick={() => handleCheckboxChange(data)}>

                    <h6 className={`${isSelected ? "text-white" : "text-dark"}`}>{data.module_label}</h6>
                    {isSelected ?
                      <FaMinus fill='#7CBB00' />
                      :
                      <FaPlus fill='#7CBB00' />
                    }
                  </div>
                </Col>
              )
            }))}

        </Row>
        <Row>
          <Col lg={{ span: 3, offset: 9 }}>
            {selectedModules.length > 0 &&
              <div className={` ${isMobile ? "text-end" : "text-start"}  mt-4 calculation-div`}>
                <span className='price-text'>Total Price</span>
                <h4>{currency} {calculateddata.grandTotal} </h4>
                {activeButton === "annual" && (
                  isMobile ? (
                    <Row>
                      <div className='col-8 offset-4'>
                        <div className={`total-saving justify-content-end d-flex`}>
                        <span className="discount-text">Total Savings: </span>
                        <span className="discount-price primary-text">
                          {currency} {calculateddata.totalSaving}
                        </span>
                      </div>
                      </div>
                    </Row>
                  ) : (
                    <div className={`total-saving ${isMobile ? "justify-content-end" : "justify-content-start"} d-flex`}>
                      <span className="discount-text">Total Savings: </span>
                      <span className="discount-price primary-text">
                        {currency} {calculateddata.totalSaving}
                      </span>
                    </div>
                  )
                )}
              </div>
            }
          </Col>
        </Row>

      </Container>
      {/* {!isMobile &&  */}

      <img src={isMobile ? ing2 : ing1} alt="" className={`img-fluid position-absolute ${isMobile ? "bottom-0" : "bottom-0"}`}></img>
      {/* } */}
    </div>
  )
}

export default Pricing

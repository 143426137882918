import React from 'react'
import * as common from '../components'

const Home = () => {
  return (
    <>
      <common.Header />
      <common.Banner />
      <common.Offer />
      <common.Why />
      <common.Modules />
      <common.Client />
      <common.Pricing />
      <common.Faqs />
      <common.Ready />
      <common.Footer />
    </>
  )
}

export default Home

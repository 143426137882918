import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import desk_bg from '../images/desktop_bg.webp'
import mob_bg from '../images/mobile_bg.webp'
import bigLine from '../images/big_line.webp'
import line from '../images/bg_line1.webp'
import { useMediaQuery } from 'react-responsive'
import { MdOutlineShoppingCart } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import { PiCashRegister } from "react-icons/pi";
import { PiGraphFill } from "react-icons/pi";
import { FaUsers } from "react-icons/fa6";
import { BsCashCoin } from "react-icons/bs";
import { LiaDigitalTachographSolid } from "react-icons/lia";
import img1 from '../images/module-ellipse.webp'


const Offer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const data = [
        {
            img: <MdOutlineShoppingCart fill="#fff" size={40} />,
            title: "E-commerce Solutions",
            description: "Expand your online store with powerful tools for inventory, orders, payments and tailored Storefronts.",
        },
        {
            img: <BiBookContent fill="#fff" size={40} />,
            title: "Content Management System (CMS)",
            description: "Streamline content creation and publishing with intuitive tools to engage your audience.",
        },
        {
            img: <PiCashRegister fill="#fff" size={40} />,
            title: "POS",
            description: "Streamline retail transactions with modern, efficient point-of-sale systems.",
        },
        {
            img: <PiGraphFill fill="#fff" size={40} />,
            title: "Dynamic Admin Panel",
            description: "Manage operations seamlessly.",
        },
        {
            img: <BiBookContent fill="#fff" size={40} />,
            title: "Asset Management",
            description: "Optimize resource allocation with smart tracking and management of assets across operations.",
        },
        {
            img: <FaUsers fill="#fff" size={40} />,
            title: "Human Resource Information System (HRIS)",
            description: "Simplify HR processes with tools for payroll, attendance, and employee management.",
        },
        {
            img: <BsCashCoin fill="#fff" size={40} />,
            title: "Accounting",
            description: "Track finances effortlessly with tools for invoicing, expense management, and real-time reporting.",
        },
        {
            img: <LiaDigitalTachographSolid fill="#fff" size={40} />,
            title: "Customer Relationship Management (CRM)",
            description: "Build stronger customer connections with tools for tracking interactions, sales, and feedback.",
        }
    ]
    return (
        <div className='offer-section position-relative' style={{ backgroundImage: `url(${isMobile ? mob_bg : desk_bg})` }}>
            {!isMobile &&
                <img src={img1} alt="line" className='img-fluid position-absolute upper-img'></img>
            }
            <Container>
                <Row >
                    <Col className='d-flex align-items-center'>
                        <div className='position-relative z-1'>
                            <h5 className='primary-text'>What we offer</h5>
                            <h2>Simplify, Innovate, and Grow with <span className='primary-text'>Shopersbay</span></h2>
                            <h6>Looking for the ideal <b>Business Software</b> to simplify and grow your business? Shopersbay is the ultimate <b>all-in-one platform</b> for seamless management and success.</h6>
                            <Row>
                                <Col lg={6} md={12}>
                                    <ul className=''>
                                        {data.slice(0, 4).map((ele, i) => {
                                            return (
                                                <li className="feature-item" key={i}>
                                                    <Row className={`w-100`}>
                                                        <div className={`col-lg-2 col-md-3 col-3 ${isMobile ? "" : "d-flex align-items-center justify-content-end"} `}>
                                                            <div className="img-icon">{ele.img}</div>
                                                        </div>
                                                        <div className='col-lg-10 col-md-9 col-9' style={{paddingRight: "0px"}}>
                                                            <div>
                                                                <h5>{ele.title}</h5>
                                                                <p>{ele.description}</p>
                                                            </div>
                                                        </div>

                                                    </Row>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </Col>
                                <Col lg={6} md={12}>
                                    <ul className=''>
                                        {data.slice(4, 8).map((ele, i) => {
                                            return (
                                                <li className="feature-item" key={i}>
                                                    <Row className={`w-100`}>
                                                        <div className={`col-lg-2 col-md-3 col-3 ${isMobile ? "" : "d-flex align-items-center justify-content-end"} `}>
                                                            <div className="img-icon">{ele.img}</div>
                                                        </div>
                                                        <div className='col-lg-10 col-md-9 col-9' style={{paddingRight: "0px"}}>
                                                            <div>
                                                                <h5>{ele.title}</h5>
                                                                <p>{ele.description}</p>
                                                            </div>
                                                        </div>

                                                    </Row>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    {/* <Col lg={6}>
                        <div className={`${isMobile ? "text-center mt-3" : "text-end"}`}>
                            <img src={isMobile ? bg2 : bg1} alt="offer_bg" className='img-fluid position-relative z-2 w-100'></img>
                        </div>
                    </Col> */}
                </Row>
            </Container>
            {!isMobile &&
                <img src={img1} alt="line" className='img-fluid position-absolute circle-img'></img>
            }
        </div>
    )
}

export default Offer
